a<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, validate, reset, invalid }"
  >
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">Notification Form</span>
        </v-card-title>
        <v-card-text>
          <v-progress-linear v-if="formLoading" indeterminate />
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <v-text-field
                    :error-messages="errors"
                    v-model="formData.title"
                    :label="$t('title')"
                    :disabled="formLoading"
                    required
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Message"
                  rules="required"
                >
                  <v-text-field
                    :error-messages="errors"
                    v-model="formData.message"
                    :label="$t('message')"
                    :disabled="formLoading"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Message Type"
                  rules="required"
                >
                  <v-autocomplete
                    :error-messages="errors"
                    :items="notification_types"
                    :disabled="formLoading"
                    :loading="formLoading"
                    item-text="value"
                    item-value="id"
                    v-model="formData.message_type"
                    label="Send to ..."
                    @change="messageTypeChange"
                    required
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" v-if="isClient">
                <ApolloQuery
                  :query="require('@/graphql/client/clientsDropdown.gql')"
                >
                  <template
                    v-slot="{ result: { loading, error, data }, isLoading }"
                  >
                    <v-autocomplete
                      v-if="data && !isLoading"
                      :items="data.allClients"
                      :disabled="formLoading"
                      :loading="formLoading"
                      item-text="name"
                      item-value="id"
                      v-model="formData.client_id"
                      label="Client"
                      clearable
                      required
                    />
                    <v-autocomplete v-else label="Loading Clients" />
                  </template>
                </ApolloQuery>
              </v-col>

              <v-col cols="12" sm="6" v-if="isUser">
                <ApolloQuery
                  :query="require('@/graphql/user/userDropdown.gql')"
                >
                  <template
                    v-slot="{ result: { loading, error, data }, isLoading }"
                  >
                    <v-autocomplete
                      multiple
                      clearable
                      v-if="data && !isLoading"
                      :items="data.allUsers"
                      :disabled="formLoading"
                      :loading="loading"
                      item-text="name"
                      item-value="id"
                      v-model="formData.users"
                      label="User"
                    />
                    <v-autocomplete v-else label="Loading Users" />
                  </template>
                </ApolloQuery>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-switch v-model="isPersistent" label="Show Daily"></v-switch>
              </v-col>
              <v-col cols="12" sm="6" v-if="isPersistent">
                <v-menu
                  ref="datepickerMenu"
                  v-model="datepickerMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="theDateText"
                      label="Expiration"
                      hint="Show daily until..."
                      persistent-hint
                      prepend-icon="mdi-calendar-outline"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.persistent_until"
                    @change="datepickerMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="$emit('notification-form-closed')"
            :disabled="formLoading"
            >{{ $t("close") }}</v-btn
          >
          <v-btn
            color="primary darken-1"
            text
            :disabled="formLoading"
            :loading="formLoading"
            @click="handleSubmit(createNotification())"
            >{{ $t("create") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { extend, setInteractionMode } from "vee-validate";
import moment from "moment";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});
export default {
  name: "NotificationForm",
  props: {
    notification: {
      type: Object,
      default: () => ({
        id: "",
        data: {
          title: "",
          message: "",
          from: "",
          persistent: "n",
          persistent_until: "2020-01-01 00:00:00"
        },
        notification_type: "",
        client: {}
      })
    }
  },
  data: () => ({
    error: null,
    datepickerMenu: false,
    isPersistent: false,
    mutation: "",
    formLoading: false,
    message_type: "",
    notification_types: [
      {
        id: "admin",
        value: "Site Administrators"
      },
      {
        id: "client_admin",
        value: "Clients (admin only)"
      },
      {
        id: "client",
        value: "Clients (all users)"
      },
      {
        id: "user",
        value: "Individual User(s)"
      },
      {
        id: "all",
        value: "Everyone"
      }
    ],
    defaultForm: {
      id: "",
      title: "",
      message: "",
      from: "",
      message_type: "",
      persistent: "n",
      persistent_until: new Date().toISOString().substr(0, 10),
      client: { id: "" },
      users: []
    },
    formData: {}
  }),
  created() {
    this.formData = { ...this.defaultForm, ...this.notification };
  },
  computed: {
    theDateText() {
      return this.formData.persistent_until
        ? this.formatDate(this.formData.persistent_until)
        : "";
    },
    isClient: {
      get: function() {
        if (
          this.formData.message_type === "client" ||
          this.formData.message_type === "client_admin"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    isUser: {
      get: function() {
        if (this.formData.message_type === "user") {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
    },
    messageTypeChange(event) {
      if (event === "admin" || event === "all") {
        this.formData.client_id = "";
        this.formData.users = "";
      } else if (event === "client" || event === "client_admin") {
        this.formData.users = "";
      }
    },
    createNotification() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        } else {
          this.mutation = require("@/graphql/notification/createNotification.gql");
          this.saveForm();
        }
      });
    },
    saveForm() {
      this.formLoading = true;
      let formVariables = {
        title: this.formData.title,
        message: this.formData.message,
        message_type: this.formData.message_type,
        persistent: this.formData.persistent,
        persistent_until: this.formData.persistent_until + " 00:00:00",
        users: this.formData.users,
        from: this.$store.getters.userForPortalAccess.id,
        client: this.formData.client_id
      };
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters
          variables: formVariables
        })
        .then(data => {
          // report success
          this.$emit("notification-form-closed", data);
          this.$emit("notification-form-saved", data);
          this.$store.dispatch("responseMessage", {
            type: "success",
            title: "Success",
            text: "Notification Saved"
          });
          this.isPersistent = false;
          this.formData = this.defaultForm;
          this.formLoading = false;
        })
        .catch(error => {
          // Error
          console.error(error);
          // update notification....
          this.formLoading = false;
        });
    }
  },
  watch: {
    isPersistent: {
      handler: function() {
        if (this.isPersistent) {
          this.formData.persistent = "y";
        } else {
          this.formData.persistent = "n";
        }
      }
    },
    notification: {
      handler: function() {
        this.formData = { ...this.defaultForm, ...this.notification };
      },
      deep: true
    }
  }
};
</script>
